:root {
  --heights: 45vw;
  --widths: 100%;
}

.slider-container {
  height: var(--heights);
  width: var(--widths);
  position: relative;
  margin: auto;
  overflow: hidden;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: var(--heights);
  width: var(--widths);
  position: relative;
}

.slide-image {
  margin: 5%;
  height: 35vw;
  margin-right: 10%;
  width: 90%;
  position: absolute;
  border-radius: 30px;
  display: block;
  justify-content: center;
}

.slide-title,
.slide-text {
  width: 90%;
  height: 100%;
  color: #fff;
  font-family: Great Vibes;

  position: absolute;
  text-align: center;
  display: grid;
  justify-content: center;
  margin: auto;
  justify-items: center;
  z-index: 1;
  left: 5%;
}

.slide-text {
  top: 45%;
  font-size: 3rem;
}

.prev,
.next {
  /* color: transparent; */
  cursor: pointer;
  position: absolute;
  top: 23vw;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 40px;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
}
.prev {
  position: absolute;
  left: 0;
}
.next {
  position: absolute;
  right: 0;
}

.slider-container:hover .prev,
.slider-container:hover .next {
  color: #fff;
}

.slider-container:hover .prev:hover,
.slider-container:hover .next:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.all-dots {
  width: 100%;
  position: absolute;
  display: flex;
  top: 41vw;
  justify-content: center;
  z-index: 200;
}

.dot {
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  margin: 0px 3px;
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.3); */
  border-radius: 50%;
  display: inline-block;
}

.slider-container:hover .dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* .active-dot {
  background-color: rgba(255, 255, 255, 0.5);
} */

.slider-container:hover .dot {
  background-color: rgba(141, 140, 140, 0.3);
}
.slider-container:hover .active-dot {
  background-color: rgba(255, 255, 255, 0.5);
}

.play-pause {
  color: #fff;
}

@media only screen and (max-width: 590px) {
  .dot {
    width: 0.5em;
    height: 0.5rem;
  }
  .slide-image {
    border-radius: 15px;
    height: 25vh;
  }
  :root {
    --heights: 30vh;
    --widths: 100%;
  }
  .all-dots {
    top: 28vh;
  }
  .prev,
  .next {
    top: 15vh;
  }
  .slide-text {
    font-size: 27px;
  }
}
