@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&family=Allura&family=Arizonia&family=Courgette&family=Great+Vibes&family=Kaushan+Script&family=Lobster+Two&family=Merriweather&family=Sail&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&family=Allura&family=Arizonia&family=Courgette&family=Great+Vibes&family=Kaushan+Script&family=Lobster+Two&family=Merriweather:wght@300;400&family=Raleway&family=Sail&family=Source+Code+Pro&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Reem Kufi", sans-serif; */
}
:root {
  flex-grow: 1;
  z-index: 1;
}
a:link {
  text-decoration: none;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
body > .skiptranslate {
  display: none;
}
.goog-te-gadget {
  float: right;
}
.goog-te-combo {
  font-size: 12px;
  border: 3px solid #08042c;
  background: #fff;
  color: black;
}
ol,
ul {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.about-section-container {
  font-family: Raleway;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #f6f6f6;
  color: #fff;
}
/* Heading */

/* Intro*/
.heading-intro {
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
}
.heading-intro h2 {
  font-size: 2.7rem;
  font-weight: 600;
}
.intro {
  color: #fff;
  margin: 0 10%;
  padding: 0 20%;
}
.intro-button {
  display: flex;
  justify-content: center;
}
.intro-text {
  display: flex;
  margin: auto;
}
.intro-section-contents {
  background: #fff;
  display: none;
  margin: 0 10%;
  font-size: 22px;
  justify-content: flex-start;
  justify-items: flex-start;
}
/* .intro-section-bottom {
  background-image: url("https://i.ibb.co/D4XK6XV/Black-Gray-and-Purple-Collage-Maximalist-Young-Adults-Birthday-Banner-1.png");
  background-size: 100% 100%;
} */
.products-intro {
  padding: 2%;
  border-bottom: 2px solid #8f8f8f;
  width: 78vw;
  color: #ab3af2;
  text-decoration: none;
  display: flex;
  align-items: center;
  background: antiquewhite;
  margin: 1%;
}

.intro-container {
  display: flex;
  overflow-x: hidden;
}
.intro-section-bottom {
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
/* .intro-text-main {
  text-align: justify;
} */
.intro-section-info {
  width: 290px;
  min-height: 350px;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: contain;
  border-radius: 1rem;
  /* color: #505050; */
  color: #fff;
  margin: 1rem 2rem;
  box-shadow: 0px 0px 12px 1px #ed3d24;
}
.overlay {
  display: grid;
  justify-content: center;
  margin: auto;
  justify-items: center;
}
.miniTitle {
  font-weight: 600;
}
.headerContent {
  font-weight: 500;
}
.intro-icons {
  width: 40px;
  height: 40px;
  color: #fff;
}
.intro-section-info img {
  width: 100%;
}
.intro-section-info h2 {
  font-family: "Sail";
  font-size: 38px;
}
/*Intro over*/
.video-thumb {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity 400ms ease 0ms;
}
.tiny {
  filter: blur(20px);
  transform: scale(1.1);
  transition: visibility 0ms ease 400ms;
}
.reveal {
  position: relative;
  transform: translateY(120px);
  opacity: 0;
  transition: 1s all ease;
}
.reveal.active {
  display: flex;
  justify-content: center;
  transform: translateY(0);
  opacity: 1;
}
table {
  border-collapse: collapse;
  /* width: 100%; */
}
th {
  background-color: #047c7c;
  color: #000;
}
th,
td {
  border: 2px solid black;
  padding: 8px;
  text-align: left;
  font-weight: 600;
  color: #000;
}

tr:nth-child(even) td,
tr:nth-child(even) th {
  background-color: white;
}

tr:nth-child(odd) td {
  background-color: aliceblue;
}
table.bodywrapcenter > tr > td {
  width: 100%;
  float: left;
}
.chart {
  margin-left: 5%;
}
.spices-separate {
  margin-top: 40%;
}
.fruit-images img {
  width: 20vw;
  height: 20vw;
  border-radius: 12px;
  border: 2px solid #ab3af2;
  transition: box-shadow 0.4s;
}
.images-information {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
}

.fruit-images img:hover {
  box-shadow: 0px 0px 3px 3px #ab3af2;
}
.package img {
  width: 20vw;
  height: 20vw;
  border-radius: 12px;
  border: 2px solid #ab3af2;
  transition: box-shadow 0.4s;
}
.package img:hover {
  box-shadow: 0px 0px 3px 3px #ab3af2;
  transform: scale(1.01);
}
.package {
  display: flex;
  justify-content: center;
  gap: 4rem;
}

.more-fruitcontent {
  text-align: center;
  padding: 2%;
  font-weight: 400;
}
.alcohol-img {
  padding: 2% 20%;
}
.fruit-section-info {
  max-width: 250px;
  width: 250px;
  min-height: 250px;
  display: flex;
  padding: 1rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #fff;
  margin-left: 3rem;
  margin-right: -1rem;
  border: 2px solid #ab3af2;
  box-shadow: 0px 0px 7px #ab3af2;
  background-size: cover;
}

.fruit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.fruit-section-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.fruit-images .fruit1 {
  display: flex;
  justify-content: center;
  margin: 2%;
  gap: 2rem;
}
.fruit1 img {
  transition: transform 0.5s;
}
.fruit1 img:hover {
  transform: scale(1.03);
}
.see-more-content {
  display: none;
  margin-left: 2%;
  margin-top: 2%;
  color: #ab3af2;
  font-size: 20px;
  font-weight: 800;
}

.fruit1 p {
  font-weight: 500;
  text-align: center;
}
.fruit-images .fruit2 {
  display: flex;
  justify-content: center;
  margin: 2%;
  gap: 2rem;
}

.youtube-video {
  width: 49rem;
  height: 27rem;
}

.one,
.two {
  display: grid;
  justify-items: center;
}

.dehydrated img {
  width: 150px;
  height: 150px;
  padding: 1rem 1rem;
  border-radius: 1rem;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  text-decoration: none;
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  display: inline-block;
  font-weight: 600;
  transition: transform 0.5s;
}
.navbar-links-container a:hover {
  background-color: #08f;
  background-size: 220px 220px;
  color: #fff;
  transform: translateY(-5px);
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.navbar-cart-icon {
  font-size: 1.15rem;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 2rem;
  cursor: pointer;
  color: #08042c;
  position: absolute;
  right: 30px;
  top: 30px;
}
.accordion {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}

.accordion-item {
  border-bottom: 1px solid #ddd;
}

.accordion-item:last-child {
  border-bottom: none;
}
.sub-accordion-body {
  padding: 2rem;
  text-align: left;
}
.accordion-header,
.sub-accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  background-color: #e2c3f6;
  border-radius: 0.25rem;
  border-bottom: 2px solid #aeaeae;
}
.sub-accordion-header {
  background-color: #ead9f5;
}
.accordion-header:hover {
  background-color: #d39ff3;
}

.sub-accordion-header:hover {
  background-color: #e1c0f5;
}

.accordion-header h2 {
  margin: 0;
}

.accordion-body {
  padding: 1rem;
}

.auto-lubricant {
  margin: 4% 10%;
  background: #fff;
  border-radius: 10px;
  display: flex;
}
.auto-lubricant-text {
  display: grid;
  position: relative;
  height: 100%;
  width: 22%;
}
.auto-lubricant-text img {
  position: absolute;
  top: 117px;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
}
.auto-lubricant img {
  border-radius: 50%;
  width: 120px;
}

.lubricant-container {
  padding: 0 10%;
}
.lubricant ul {
  text-align: left;
  padding: 2% 5%;
}
.images-container {
  display: grid;
  justify-content: center;
  margin: 3%;
  justify-items: center;
}
.metal-image {
  padding: 2% 3%;
  width: 50%;
  margin: auto;
  display: flex;
}
.metal-over-text {
  position: absolute;
  font-size: clamp(1rem, 3vw, 1.5rem);
  display: flex;
  flex-wrap: wrap;
  padding: 0 25%;
  margin-top: -264px;
  color: #fff;
}
.metal-content-bottom {
  display: flow-root;
}
.images-container h3 {
  margin-top: -36px;
  color: #fff;
  background: #908e8e;
  border-bottom: 7px solid #000;
  border-radius: 4px;
}
.spices-button {
  display: flex;
  justify-content: center;
  margin: auto;
}
.home-container {
  overflow: hidden;
  width: 100%;
}

.home-heading {
  color: #fff;
  margin-top: 4%;
  font-size: 25px;
  line-height: 1.5;
  text-align: center;

  padding: 0 5% 0 5%;
}
.box h1 {
  font-size: 2.5rem;
  color: white;
}
.home-img {
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
}
.home-banner-container {
  /* background-image: linear-gradient(#ab3af2, #f28234); */

  position: relative;
  display: flex;
  /* z-index: -1; */
  width: 100%;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: 0;
  max-width: 700px;
}

.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  /* background-attachment: fixed; */
  background: url("https://i.ibb.co/k1rHtXf/Untitled-3.png");
  background-size: 100% 100%;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: 100% 100%;
  height: 100vh;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-name-spices {
  position: absolute;
  color: white;
  border: 2px solid white;
  display: table;
  margin-left: 14%;
  margin-right: 14%;
}
video::-webkit-media-controls {
  display: none;
}
.separator {
  margin-top: 20%;
  margin-bottom: 10%;
  display: -webkit-inline-box;
}
.work-button {
  margin-top: 15%;
  display: flex;
  justify-content: center;
}
.banner-work {
  position: absolute;
  z-index: -1;
  right: 0;
}
.work-images {
  display: flex;
  gap: 5%;
  margin-left: 8%;
  margin-right: 8%;
}
.work-video {
  justify-content: center;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 30%;
  overflow: hidden;
  padding: 5%;
}
.work-image-1 {
  border-radius: 30px;
  overflow: hidden;
  border-bottom: 10px solid #fe9e0d;
  transition: transform 0.5s;
}
.work-image-2 {
  border-radius: 30px;
  overflow: hidden;
  border-bottom: 10px solid #fe9e0d;
  transition: transform 0.5s;
}
.work-image-1:hover {
  transform: scale(1.1);
}
.work-image-2:hover {
  transform: scale(1.1);
}
.primary-heading {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 20px;
  font-size: clamp(1.5rem, 3vw, 2rem);

  /* line-height: 5rem; */
  /* max-width: 600px; */
}
.sub-sub-primary-heading {
  font-weight: 500 !important;

  font-size: 22px;
}

.primary-text {
  font-weight: 400;
  text-align: center;
  max-width: 570px;
  font-size: clamp(1rem, 3vw, 1.5rem);
  /* color: #474747; */
  color: #fff;
}
.primary-information {
  color: #fff;
  padding: 1rem;
  font-family: Raleway;
  font-weight: 500;
}
.lists-primary-text {
  font-weight: 400;
  text-align: left;
  padding: 0 7%;
  max-width: 700px;
  font-size: clamp(1rem, 3vw, 1.5rem);
  color: #fff;
}
.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #ed3d24;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #fff;
  transition: transform 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .secondary-button:hover {
  transform: translateY(5px);
} */

.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #0f81e4;
  color: #fff;
  font-weight: 700;
  transition: 0.5s all ease-in-out;
}
.about-section-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.link-products {
  font-weight: 600;
  color: #fff;
}
.link-products:hover {
  color: #fff;
  font-weight: 900;
}
.heading-recycling {
  font-family: Great Vibes;
  font-size: 35px;
}
.subheading-recycling {
  color: #fff;
}
.about-recycling p {
  font-weight: 400;
  font-size: clamp(1rem, 3vw, 1.5rem);
  color: #fff;
}
.about-recycling {
  margin-bottom: 10%;
}
.color-more {
  color: #fff;
  font-size: 20px;
}
.image-container-hero {
  padding: 0 3%;
  box-shadow: 0px 0px 10px 1px #ed3d24;
}

.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.primary-subheading {
  font-weight: 700;
  font-size: 2rem;
  font-family: Great Vibes;
}
.primary-mainheading {
  font-family: Sail;
  width: fit-content;
  margin: auto;
  font-size: 4rem;
  box-shadow: 0 0 3px 3px #daaff5;
  padding: 0;
  border-radius: 10px;
  border-bottom: 2px solid #9b9a9a;
}
.primary-subheading img {
  width: 250px;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-watch-video {
  display: flex;
  justify-content: center;
  margin: 6%;
  margin-bottom: 42px;
}
.products-heading {
  font-family: Great Vibes;
  margin: 5vh 0%;
  font-size: 10vh;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-head {
  font-weight: 400;
  text-align: center;
}
.contact {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("https://i.ibb.co/dgsN8LL/Whats-App-Image-2023-04-02-at-21-19-51.jpg");
  background-repeat: repeat;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper .primary-heading {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  text-align: left;
}
.submit-button {
  background-color: #08042c;
  height: 3rem;
  color: white;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contact-form-container {
  background: #fff;
  width: 50rem;
  margin-top: 3rem;
  display: grid;
  align-items: center;
  padding: 2rem;
  color: #000;
}

/* .contact-banner {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("https://i.ibb.co/dgsN8LL/Whats-App-Image-2023-04-02-at-21-19-51.jpg");
  height: 20rem;
  width: 100%;
} */
/* .contact-page-wrapper .contact-over-banner {
  color: #fafafa;
  font-family: Great Vibes;
  display: flex;
  font-size: 3rem;
  top: 14%;
  position: absolute;
  text-align: center;
} */
.contact-over-banner {
  color: #fafafa;
  font-family: Great Vibes;
  font-size: 3rem;
  text-align: center;
  padding-top: 5rem;
}
.contact-name-email {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.contact-form-container input {
  height: 100%;
  width: 15rem;
  border: 0.5px solid #08042c;

  padding: 9px;
}

.contact-details {
  display: grid;
  margin: auto;
  background: #fff;
  margin: 10%;
  width: 80%;
  padding: 16px;
  border-radius: 10px;
}
.contact-details p {
  font-size: 1.1rem;
  font-weight: 500;
  color: #000;
  text-align: left;
  margin-bottom: 5vw;
}
.contact-form-container select {
  height: 100%;
  border: 0.5px solid #08042c;
  padding: 9px;
  width: 15rem;
}
textarea:focus,
input:focus,
select:focus {
  outline: none;
}

.whatsapp_float {
  transition: transform 0.5s;
  position: fixed;
  z-index: 9999;
  right: 0;
  bottom: 0;
  -webkit-tap-highlight-color: transparent;
  padding: 3%;
  margin-bottom: 10%;
}
.whatsapp_float img {
  border-radius: 50%;
}
.whatsapp_float a {
  display: block;
}
/* .whatsapp_float.draggable {
  cursor: move;
} */
.whatsapp_float:hover {
  transform: scale(1.2);
}

.contact-form-container textarea {
  border: 0.5px solid #08042c;
  background: transparent;
  padding: 9px;
}
.contact-form-container label {
  text-align: left;
}
form {
  display: grid;
}
.footer-banner {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 6.5vw;
  max-width: 100%;
  margin-bottom: -3px;
  background-image: url("https://i.ibb.co/v1vXBBk/svg-edited-4.png");
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -5.5vw;
}
.footer-wrapper {
  display: flex;
  padding-bottom: 48px;
  background-color: #08042c;
  color: #fff !important;
  align-items: center;
}
.footer-icons {
  display: flex;
}
.footer-logo-container {
  max-width: 130px;
}
.footer-icons {
  margin-top: 2.5rem;
}
.footer-icons svg {
  font-size: 2rem;
  margin-right: 1.25rem;
  color: #fff;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-one {
  display: inline-table;
  justify-items: center;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  padding: 2%;
  justify-content: center;
  margin-left: 10%;
}

.footer-section-columns a {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}
.testimonials-stars-container {
  display: flex;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
/* Work Testimonial */
.carousel-root {
  width: 64%;
  margin: auto;
  margin-top: 3%;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 170px !important;
  height: 175px;
  border-radius: 10px;

  overflow: hidden;
}
.carousel-director img {
  width: 220px;
  border-radius: 20%;
  height: 240px;
}
.carousel-director {
  margin-bottom: 9%;
}
.director-images {
  box-shadow: 0 0 12px 1px #ed3d24;
}
.myCarousel {
  background: #fafafa;
  margin-top: -6%;

  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  height: 450px;
  border-radius: 20px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  bottom: 5% !important;
}
.carousel-contact img {
  width: 40px !important;
  height: 40px;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-top: 6%;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 500;
  line-height: 26px !important;
  color: #222;
  font-size: 17px;
  text-align: center;
  font-family: Raleway;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: Raleway;
  font-weight: 500;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: Raleway;
  font-weight: 500;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 680px;
  }
}
/*End*/
.work-section-wrapper {
  margin-top: 2rem;
  padding: 0 5%;
}
.work-spices-text {
  margin: 0 5%;
  font-weight: 400;
  text-align: center;
  max-width: 570px;
  font-size: clamp(1rem, 3vw, 1.5rem);
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
  font-weight: 400;
  padding: 3%;
  /* margin-top: 9%; */
}
.work-section-top ul li {
  text-align: left;
}
.line-dividing {
  color: #fff;
  width: 90%;
  display: flex;
  margin: auto;
  border-bottom: 2px solid #fff;
}
.line-dividing-metal {
  color: #fff;
  width: 90%;
  display: flex;
  margin: auto;
  border-bottom: 2px solid #fff;
  padding: 3% 3%;
}
.guar-gum {
  padding: 5%;
  display: flex;
  justify-content: center;
}
.work-spices-text {
  text-align: center;
  max-width: 600px !important;
  font-weight: 400;
  padding: 3%;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  text-align: center;
  font-weight: 400;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  background-color: #08042c;
  color: #fff;
}
.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  /* color: #505050; */
  margin: 1rem 2rem;

  color: #fff;
  box-shadow: 0px 0px 3px 3px #ab3af2;
}
.work-section-info h2 {
  margin: 1rem 0rem;
  font-weight: 500;
}
.info-boxes-img-container {
  transition: transform 0.4s;
}
.info-boxes-img-container:hover {
  transform: scale(1.3);
}

.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 400;
}
@media (max-width: 1122px) {
  .intro-section-info {
    padding: 0;
  }
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
  .intro-section-info,
  .intro-section-bottom {
    display: grid;
    width: 40vh;
    justify-content: center;
    padding: 1rem 2rem;
    align-items: center;
    justify-items: center;
    margin: auto;
  }
  .intro-section-info {
    margin-bottom: 10%;
  }
  .intro-container {
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .navbar-menu-container {
    display: flex;
    /* position: absolute;
    right: 0; */
  }
}
@media (max-width: 800px) {
  .auto-lubricant {
    margin: 4% 5%;
    display: grid;
  }
  .youtube-video {
    width: -webkit-fill-available;
    height: 15rem;
  }
  .metal-over-text {
    padding: 0px 3rem;
    margin-top: -184px;
  }
  .fruit-container {
    overflow-x: scroll;
  }
  .fruit-section-bottom {
    display: -webkit-inline-box;
    margin-left: 10em;
    flex-wrap: initial;
  }
  .metal-image {
    width: 100%;
  }
  .auto-lubricant img {
    position: relative;
  }
  .auto-lubricant-text {
    display: block;
    width: 100%;
  }
  .auto-lubricant-text img {
    display: initial;
    top: 0;
    left: 0;
    transform: none;
  }

  .work-images {
    display: grid;
  }
  .contact-name-email {
    display: grid;
  }
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }

  .home-bannerImage-container {
    max-width: 450px;
  }

  .contact-page-wrapper .contact-over-banner {
    font-size: 2.5rem;
    top: 14%;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 100%;
    margin: 3%;
  }
  .primary-text {
    text-align: center;
    padding: 7%;
    max-width: 80%;
  }
  .work-spices-text {
    text-align: center;
    max-width: 80%;
  }
  .spices {
    margin-top: 7%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    padding-bottom: 25%;
    background-size: 100% 100%;
  }

  .secondary-button {
    margin: 21px;
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }

  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
    margin-top: 3%;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 2rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    background-color: white;
    margin-top: 3rem;
    display: grid;
    align-items: left;
  }
  .contact-form-container input {
    margin-bottom: 0.8rem;
  }

  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
    align-items: center;
    justify-content: center;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
    text-align: center;
  }
  .contact-form-container {
    width: 20rem;
    text-align: left;
    display: flex;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container {
    display: none;
  }
  .footer-wrapper {
    display: grid;
    justify-content: center;
  }
  .contact-form-container {
    width: 20rem;
    text-align: left;
    display: flex;
  }
  .whatsapp_float {
    bottom: 2%;
    margin-right: 8%;
  }
  .see-more-content {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 590px) {
  .alcohol-img {
    padding: 2% 7%;
  }
  .box {
    width: 20rem;
  }
  .form-name-spices,
  .work-button {
    display: none;
  }

  .spices-separate {
    margin-top: 60%;
  }
  .home-heading {
    font-size: 16px;
  }
  .box h1 {
    margin-top: 8rem;
  }
  .fruit-images .fruit1 {
    display: grid;
    padding: 5%;
  }
  /* .intro-section-info {
    display: none;
  } */
  .one p {
    font-weight: 700;
  }
  .work-video {
    border-radius: 5%;
    padding: 0;
  }
  .fruit-images img {
    width: 25vh;
    height: 22vh;
  }
  .intro {
    padding: 0 5%;
    width: 100%;
    border-radius: 10px;
    margin: 0 5%;
  }

  .intro-section-contents {
    padding: 0 5%;
    display: grid;
    border-radius: 10px;
    margin: 0 5%;
  }
  .package img {
    width: 25vh;
    height: 22vh;
  }
  .package {
    display: grid;
  }
  .contact-form-container input {
    padding: 0;
  }
  .contact-form-container textarea {
    padding: 0;
  }
  .work-section-top-spices p {
    margin-top: 26%;
  }

  .contact-page-wrapper .contact-over-banner {
    padding-top: 5rem;
  }
  .myCarousel {
    height: 426px;
  }
  .primary-mainheading {
    width: auto;
    margin: auto;
    font-size: 55px;
  }
}
@media (hover: hover) {
  button:hover {
    outline: 0;
  }
  a:hover {
  }
  .hamburger:hover {
    outline: 0;
  }
}
button:focus {
  outline: 0;
}
a:focus {
  outline: 0;
}
