@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&family=Allura&family=Arizonia&family=Courgette&family=Great+Vibes&family=Kaushan+Script&family=Lobster+Two&family=Merriweather:wght@300;400&family=Raleway&family=Sail&family=Source+Code+Pro&display=swap");

* {
  margin: 0;
}
body {
  background-color: #08042c;
  /* background-color: #08c4cc; */
  color: #fff;
  margin: 0;
  font-family: Raleway;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1a3353;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1a3353;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}
.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none;
}
